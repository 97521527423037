var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-table", {
          ref: "grid1",
          attrs: {
            topBorderClass: "topcolor-lightblue",
            title: "평가항목별 감사결과",
            tableId: "grid1",
            columnSetting: false,
            usePaging: false,
            filtering: false,
            hideBottom: true,
            columns: _vm.grid1.columns,
            data: _vm.deptData.itemList,
            merge: _vm.grid1.merge,
            gridHeight: _vm.gridheight,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }